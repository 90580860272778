import React from "react";

import "./footer.css";
import { Link } from "react-router-dom";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import { green } from "@mui/material/colors";
import PhoneIcon from "@mui/icons-material/Phone";
import MapIcon from "@mui/icons-material/Map";
import SendIcon from "@mui/icons-material/Send";
const Footer = () => {
  return (
    <>
      {/* <section className="newletter">
        <div className="container flexSB">
          <div className="left row">
            <h1>Newsletter - Stay tune and get the latest update</h1>
            <span>Far far away, behind the word mountains</span>
          </div>
          <div className="right row">
            <input type="text" placeholder="Enter email address" />
            <i className="fa fa-paper-plane"></i>
          </div>
        </div>
      </section> */}
      <footer>
        <div className="container padding">
          <div className="box logo">
            <h1>SHIVDARSHAN ENTERPRISES</h1>
            <span>A STEP TOWARDS AFFORDABLE HEALTHCARE</span>
            <p>
              We Provides Installation of Clean Room Equipment, Panels, Doors,
              Window, Aluminium, Sliding Work, MS Fabrication & Pipe
              Installation
            </p>

            <a
              href="https://www.facebook.com/shivdarshan.enterprises?mibextid=ZbWKwL"
              alt=""
            >
              <FacebookOutlinedIcon sx={{ color: green[500], fontSize: 40 }} />
            </a>

            {/* <i className="fab fa-instagram icon"></i> */}
          </div>
          <div className="box link">
            <h3>Explore</h3>
            <ul>
              <li>
                {" "}
                <Link to="/our-services" className="linkk">
                  Our Services
                </Link>
              </li>

              <li>
                <Link to="/contact-us" className="linkk">
                  Contact Us
                </Link>
              </li>
              <li>
                {" "}
                <Link to="/about-us" className="linkk">
                  About Us
                </Link>
              </li>
            </ul>
          </div>

          <div className="box last">
            <h3>Have a Questions?</h3>
            <ul>
              <li>
                <MapIcon sx={{ color: green[500], fontSize: 20 }} />
                Office:- A/F 103, Nakoda Apartment, Chharwada Ramzanwadi, Vapi,
                Dist. Valsad, Gujarat - 396191 Reg. Address:- Khadda (Tamaspur),
                Post.- Ahirauli Bazaar, Dist.– Kushinagar (UttarPradesh) PAN
                No.– ISTPS6674H, GST – 09ISTPS6674H1ZH
              </li>
              <li>
                <PhoneIcon sx={{ color: green[500], fontSize: 20 }} />
                +91 7779059399/ 8318941554
              </li>
              <li>
                <SendIcon sx={{ color: green[500], fontSize: 20 }} />
                shivdarshan.enterprises@rediffmail.com
              </li>
            </ul>
          </div>
        </div>
      </footer>
      <div className="legal">
        <p>Copyright ©2023 All rights reserved by SHIVDARSHAN ENTERPRISES</p>
        <p>Powered by amzsoft innovexa</p>
      </div>
    </>
  );
};

export default Footer;
