import "./App.css";

import { Routes, Route } from "react-router-dom";
import About from "./components/about/About";

import Contact from "./components/contact/Contact";

import Layout from "./components/home/Layout";

import Product from "./components/products/product";

function App() {
  return (
    <>
      <Routes>
        {/* <Header /> */}
        {/* <Route path="/list" element={<AllProduct />} />/. */}
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/" element={<Layout />} />
        <Route path="/about-us" element={<About />} />
        {/* <Route path="/courses" element={<CourseHome />} />
        <Route path="/team" element={<Team />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/journal" element={<Blog />} /> */}
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/our-services" element={<Product />} />

        {/* <Footer /> */}
      </Routes>
    </>
  );
}

export default App;
