import React from "react";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import { green } from "@mui/material/colors";
const Head = () => {
  return (
    <>
      <section className="head">
        <div className="container flexSB">
          <div className="logo">
            <h1>
              <img src="/images/logo1.png" alt="" />
              SHIVDARSHAN ENTERPRISES
            </h1>
            <span>A STEP TOWARDS AFFORDABLE HEALTHCARE</span>
          </div>

          <div className="social">
            <a
              href="https://www.facebook.com/shivdarshan.enterprises?mibextid=ZbWKwL"
              alt=""
            >
              <FacebookOutlinedIcon sx={{ color: green[500], fontSize: 40 }} />
            </a>
            {/* <i className="fab fa-instagram icon"></i> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Head;
