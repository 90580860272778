import React from "react";

import "./about.css";
import Heading from "../common/heading/Heading";

const AboutCard = () => {
  return (
    <div className="about-us">
      <Heading subtitle="" title="About Us" />
      <h2>Welcome to Shivdarshan Enterprises!</h2>
      <p>
        At Shivdarshan Enterprises, we are dedicated to providing top-notch
        services in the installation of clean room equipment, panels, doors,
        windows, aluminium fixtures, sliding work, MS fabrication, and pipe
        installation in the healthcare field. With a focus on precision,
        quality, and innovation, we strive to meet the specific needs of our
        clients and contribute to the advancement of the healthcare industry.
      </p>
      <h3>Our Mission</h3>
      <p>
        Our mission at Shivdarshan Enterprises is to be the leading provider of
        clean room installation services in the healthcare sector. We are
        committed to helping healthcare facilities maintain the highest
        standards of cleanliness, safety, and efficiency by offering reliable,
        state-of-the-art installation solutions. Through our expertise and
        dedication, we aim to play an integral role in creating environments
        that promote patient well-being and enable medical professionals to
        deliver exceptional care.
      </p>
      <h3>Why Choose Us?</h3>
      <ul>
        <li>
          <span>Expertise and Experience:</span> With years of experience in the
          industry, our team of skilled professionals...
        </li>
        <li>
          <span>Quality Assurance:</span>We understand the critical nature of
          clean room installations in healthcare environments...
        </li>
        <li>
          <span>Customized Solutions:</span> We recognize that every healthcare
          facility is unique, and their requirements may differ...
        </li>
        <li>
          <span>Timely Execution:</span> Time is of the essence in the
          healthcare industry. Our efficient project management and dedicated
          team...
        </li>
        <li>
          <span>Innovative Approach:</span> We keep abreast of the latest
          advancements in clean room technology and installation techniques...
        </li>
        <li>
          <span>Customer Satisfaction:</span> Client satisfaction is at the
          heart of everything we do...
        </li>
      </ul>
      <h3>Our Services</h3>
      <ul>
        <li>
          <span>Clean Room Equipment Installation:</span> We specialize in
          installing a wide range of clean room equipment...
        </li>
        <li>
          <span>Panels, Doors, and Windows Installation:</span> Our skilled
          technicians handle the installation of clean room panels, doors, and
          windows...
        </li>
        <li>
          <span>Aluminium Fixtures and Sliding Work:</span> We offer aluminium
          fixture installation services, including sliding doors and windows...
        </li>
        <li>
          <span>MS Fabrication:</span> Our expertise in mild steel fabrication
          ensures sturdy and reliable structures...
        </li>
        <li>
          <span>Pipe Installation:</span> We provide professional pipe
          installation services, ensuring the seamless integration of essential
          systems...
        </li>
      </ul>
      <p>
        At Shivdarshan Enterprises, we take pride in being your trusted partner
        in creating state-of-the-art clean room environments for the healthcare
        industry. Contact us today to discuss your project requirements and let
        us help you achieve excellence in cleanliness, safety, and efficiency.
      </p>
    </div>
  );
};

export default AboutCard;
