export const online = [
  {
    cover: "./images/clientlogo/logo1.jpeg",
  },
  {
    cover: "./images/clientlogo/logo2.jpeg",
  },
  {
    cover: "./images/clientlogo/logo3.png",
  },
  {
    cover: "./images/clientlogo/logo4.png",
  },
  {
    cover: "./images/clientlogo/logo5.png",
  },
  {
    cover: "./images/clientlogo/logo6.png",
  },
  {
    cover: "./images/clientlogo/logo7.png",
  },
  {
    cover: "./images/clientlogo/logo8.png",
  },
  {
    cover: "./images/clientlogo/logo9.png",
  },
  {
    cover: "./images/clientlogo/logo10.png",
  },
  {
    cover: "./images/clientlogo/logo11.png",
  },
  {
    cover: "./images/clientlogo/logo12.png",
  },
  {
    cover: "./images/clientlogo/logo13.png",
  },
  {
    cover: "./images/clientlogo/logo14.png",
  },
  {
    cover: "./images/clientlogo/logo15.png",
  },
  {
    cover: "./images/clientlogo/logo16.png",
  },
  {
    cover: "./images/clientlogo/logo18.jpg",
  },
  {
    cover: "./images/clientlogo/logo17.png",
  },
];

export const faq = [
  {
    title: "What industries do you serve for clean room installations?",
    desc: "We primarily serve the healthcare industry, including hospitals, clinics, laboratories, and pharmaceutical facilities.",
  },
  {
    title:
      "Are your installations compliant with industry standards and regulations?",
    desc: "Yes, we strictly adhere to all relevant industry standards and regulations to ensure the highest quality installations.",
  },
  {
    title:
      "Can you customize clean room installations according to our specific requirements?",
    desc: "Absolutely! We understand the unique needs of each healthcare facility and offer customized solutions to meet those requirements.",
  },
  {
    title: "How long does a typical installation project take to complete?",
    desc: "The project timeline may vary based on the scope and complexity of the installation. However, we always strive for timely execution to minimize any disruptions to your operations.",
  },
  {
    title: "Do you provide ongoing support and maintenance services?",
    desc: "Yes, we offer ongoing support and maintenance services to ensure the continued performance and efficiency of your clean room installations.",
  },
  {
    title: "How can we contact your team to discuss our project?",
    desc: "You can reach out to us through our contact page on the website or give us a call at +91 7779059399/ 8318941554. Our team will be happy to assist you.",
  },
];
