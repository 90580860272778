import React from "react";

import "./Hero.css";

const Hero = () => {
  return (
    <>
      <section className="hero">
        <div className="container">
          <div className="row">
            <h3>WELCOME TO SHIVDARSHAN ENTERPRISES</h3>
            {/* <Heading subtitle="WELCOME TO SHIVDARSHAN ENTERPRISES" title="" /> */}
            <p>
              We Provides Installation of Clean Room Equipment, Panels, Doors,
              Window, Aluminium, Sliding Work, MS Fabrication & Pipe
              Installation
            </p>
            {/* <div className="button">
              <button className="primary-btn">
                Our Services <i className="fa fa-long-arrow-alt-right"></i>
              </button>
              <button className="sen-btn">
                Contact US<i className="fa fa-long-arrow-alt-right"></i>
                <Link to="/product" ></Link>
              </button>
            </div> */}
          </div>
        </div>
      </section>
      <div className="margin"></div>
    </>
  );
};

export default Hero;
