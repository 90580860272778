import React from "react";
import "./Hmiddle.css";
import Heading from "../common/heading/Heading";

const Hmiddle = () => {
  return (
    <div>
      <Heading subtitle="" title="Our Services" />
      <div className="leftt">
        <div className="leftti">
          <img src="/images/simg/bms.jpg" alt="" />
        </div>
        <div className="lefttd">
          <h3>BMS CABLING</h3>
          <p>
            Our BMS cables are manufactured using premium-grade materials,
            ensuring durability, longevity, and optimal performance. We
            prioritize quality to provide you with a reliable cabling
            infrastructure that stands the test of time.
          </p>
        </div>
      </div>
      <div className="rightt">
        <div className="righttd">
          <h3>CLEAN ROOM DOOR</h3>
          <p>
            Shivdarshan Cleanroom Doors widely used in cleaning workshops,
            hospitals, pharmaceutical factories, food factories, etc ,it has
            good performance at outside facer,flat ,high structural strength,
            corrosion resistance, no dust, easy to clean .the most important is
            easy installation for your projects.
          </p>
        </div>
        <div className="rightti">
          <img src="/images/simg/cleanroom.jpg" alt="" />
        </div>
      </div>
      <div className="leftt">
        <div className="leftti">
          <img src="/images/simg/chillarpump.jpg" alt="" />
        </div>
        <div className="lefttd">
          <h3>CHILLAR PUMP</h3>
          <p>
            The purpose of a chiller is to utilize the power of outside air and
            water to maintain the target temperature at a constant level.
            Therefore, it can be used to cool or heat. Because it is necessary
            to keep the temperature constant at all times, it is not limited to
            cooling.
          </p>
        </div>
      </div>
      <div className="rightt">
        <div className="righttd">
          <h3>HVAC</h3>
          <p>
            Our HVAC systems are engineered to prioritize indoor air quality, a
            crucial factor in medical settings. With advanced filtration
            systems, our products effectively remove contaminants, allergens,
            and airborne pathogens, ensuring clean and purified air circulation.
          </p>
        </div>
        <div className="rightti">
          <img src="/images/simg/HVAC2.jpg" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Hmiddle;
