import React from "react";
import "./about.css";
import Back from "../common/back/Back";
import AboutCard from "./AboutCard";
import Header from "../common/header/Header";
import Footer from "../common/footer/Footer";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="SHIVDARSHAN ENTERPRISES,
  A STEP TOWARDS AFFORDABLE HEALTHCARE,
  
  We Provides Installation of Clean Room Equipment, Panels, Doors, Window, Aluminium, Sliding Work, MS Fabrication & Pipe Installation.
  Office:- A/F 103, Nakoda Apartment, Chharwada Ramzanwadi, Vapi, Dist. Valsad, Gujarat - 396191 Reg. Address:- Khadda (Tamaspur), Post.- Ahirauli Bazaar, Dist.– Kushinagar (UttarPradesh)"
        />
        <meta
          name="keywords"
          content=" Clean Room Equipment, Panels, Doors, Window, Aluminium, Sliding Work, MS Fabrication & Pipe Installation"
        />
        <meta name="author" content="amzsoft innovexa" />
        <title>About-Us Shivdarshan Enterprises</title>
      </Helmet>
      <Header />
      <Back title="About Us" />
      <AboutCard />
      <Footer />
    </>
  );
};

export default About;
