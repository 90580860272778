import React, { useRef } from "react";
import Back from "../common/back/Back";
import "./contact.css";
import Header from "../common/header/Header";
import Footer from "../common/footer/Footer";
import emailjs from "@emailjs/browser";
import { Helmet } from "react-helmet";
import Heading from "../common/heading/Heading";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_rhzqelm",
        "template_mxej24k",
        form.current,
        "62ZqPArBNoVGWw9XB"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  // const map =
  //   'https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d904726.6131739549!2d85.24565535!3d27.65273865!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2snp!4v1652535615693!5m2!1sen!2snp" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" ';
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="SHIVDARSHAN ENTERPRISES,
  A STEP TOWARDS AFFORDABLE HEALTHCARE,
  
  We Provides Installation of Clean Room Equipment, Panels, Doors, Window, Aluminium, Sliding Work, MS Fabrication & Pipe Installation.
  Office:- A/F 103, Nakoda Apartment, Chharwada Ramzanwadi, Vapi, Dist. Valsad, Gujarat - 396191 Reg. Address:- Khadda (Tamaspur), Post.- Ahirauli Bazaar, Dist.– Kushinagar (UttarPradesh)"
        />
        <meta
          name="keywords"
          content=" Clean Room Equipment, Panels, Doors, Window, Aluminium, Sliding Work, MS Fabrication & Pipe Installation"
        />
        <meta name="author" content="amzsoft innovexa" />
        <title>Contact-Us Shivdarshan Enterprises</title>
      </Helmet>
      <Header />
      <Back title="Contact us" />
      <section className="contacts ">
        <Heading subtitle="" title="Contact Us" />
        <div className="container shadow flexSB">
          {/* <div className="left row">
            <iframe src={map}></iframe>
          </div> */}
          <div className="right row">
            <h1>Contact us</h1>
            <p>We're open for your query or just to have a chat</p>

            <div className="items grid2">
              <div className="box">
                <h4>ADDRESS:</h4>
                <p>
                  Office:- A/F 103, Nakoda Apartment, Chharwada Ramzanwadi,
                  Vapi, Dist. Valsad, Gujarat - 396191{" "}
                </p>
              </div>
              {/* <div className="box">
                <h4>EMAIL:</h4>
                <p className="pemaill">
                  {" "}
                  shivdarshan.enterprises@rediffmail.com
                </p>
              </div> */}
              <div className="box">
                <h4>PHONE:</h4>
                <p> +91 7779059399/ 8318941554</p>
              </div>
            </div>

            <form ref={form} onSubmit={sendEmail}>
              <div className="flexSB">
                <input type="text" placeholder="Name" name="user_name" />
                <input type="email" placeholder="Email" name="user_email" />
              </div>
              <input type="text" placeholder="Subject" name="subject" />
              <textarea
                cols="30"
                rows="10"
                name="message"
                placeholder="enter your message"
              />

              <button className="primary-btn">SEND MESSAGE</button>
            </form>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Contact;
