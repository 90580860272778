import React from "react";

import Hero from "./hero/Hero";

import Faq from "../pricing/Faq";
import Hmiddle from "./Hmiddle";
import OnlineCourses from "../allcourses/OnlineCourses";
import { Helmet } from "react-helmet";
const Home = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="SHIVDARSHAN ENTERPRISES,
  A STEP TOWARDS AFFORDABLE HEALTHCARE,
  
  We Provides Installation of Clean Room Equipment, Panels, Doors, Window, Aluminium, Sliding Work, MS Fabrication & Pipe Installation.
  Office:- A/F 103, Nakoda Apartment, Chharwada Ramzanwadi, Vapi, Dist. Valsad, Gujarat - 396191 Reg. Address:- Khadda (Tamaspur), Post.- Ahirauli Bazaar, Dist.– Kushinagar (UttarPradesh)"
        />
        <meta
          name="keywords"
          content=" Clean Room Equipment, Panels, Doors, Window, Aluminium, Sliding Work, MS Fabrication & Pipe Installation"
        />
        <meta name="author" content="amzsoft innovexa" />
        <title>
          Shivdarshan Enterprises A STEP TOWARDS AFFORDABLE HEALTHCARE
        </title>
      </Helmet>
      <Hero />
      <Hmiddle />

      <OnlineCourses />

      <Faq />
    </>
  );
};

export default Home;
