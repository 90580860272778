import React from "react";
import "./product.css";
import Heading from "../common/heading/Heading";

import Footer from "../common/footer/Footer";
import Header from "../common/header/Header";
import Back from "../common/back/Back";
import { Helmet } from "react-helmet";
const Product = () => {
  return (
    <div className="prt">
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="SHIVDARSHAN ENTERPRISES,
  A STEP TOWARDS AFFORDABLE HEALTHCARE,
  
  We Provides Installation of Clean Room Equipment, Panels, Doors, Window, Aluminium, Sliding Work, MS Fabrication & Pipe Installation.
  Office:- A/F 103, Nakoda Apartment, Chharwada Ramzanwadi, Vapi, Dist. Valsad, Gujarat - 396191 Reg. Address:- Khadda (Tamaspur), Post.- Ahirauli Bazaar, Dist.– Kushinagar (UttarPradesh)"
        />
        <meta
          name="keywords"
          content=" Clean Room Equipment, Panels, Doors, Window, Aluminium, Sliding Work, MS Fabrication & Pipe Installation"
        />
        <meta name="author" content="amzsoft innovexa" />
        <title>Our-Services Shivdarshan Enterprises</title>
      </Helmet>
      <Header />
      <Back title="Our Services" />
      <Heading subtitle="" title="Our Services" />
      <div className="leftt">
        <div className="leftti">
          <img src="/images/simg/bms.jpg" alt="" />
        </div>
        <div className="lefttd">
          <h3>BMS CABLING</h3>
          <p>
            Our BMS cables are manufactured using premium-grade materials,
            ensuring durability, longevity, and optimal performance. We
            prioritize quality to provide you with a reliable cabling
            infrastructure that stands the test of time.
          </p>
        </div>
      </div>
      <div className="rightt">
        <div className="righttd">
          <h3>CLEAN ROOM DOOR</h3>
          <p>
            Shivdarshan Cleanroom Doors widely used in cleaning workshops,
            hospitals, pharmaceutical factories, food factories, etc ,it has
            good performance at outside facer,flat ,high structural strength,
            corrosion resistance, no dust, easy to clean .the most important is
            easy installation for your projects.
          </p>
        </div>
        <div className="rightti">
          <img src="/images/simg/cleanroom.jpg" alt="" />
        </div>
      </div>
      <div className="leftt">
        <div className="leftti">
          <img src="/images/simg/chillarpump.jpg" alt="" />
        </div>
        <div className="lefttd">
          <h3>CHILLAR PUMP</h3>
          <p>
            The purpose of a chiller is to utilize the power of outside air and
            water to maintain the target temperature at a constant level.
            Therefore, it can be used to cool or heat. Because it is necessary
            to keep the temperature constant at all times, it is not limited to
            cooling.
          </p>
        </div>
      </div>
      <div className="rightt">
        <div className="righttd">
          <h3>HVAC</h3>
          <p>
            Our HVAC systems are engineered to prioritize indoor air quality, a
            crucial factor in medical settings. With advanced filtration
            systems, our products effectively remove contaminants, allergens,
            and airborne pathogens, ensuring clean and purified air circulation.
          </p>
        </div>
        <div className="rightti">
          <img src="/images/simg/HVAC2.jpg" alt="" />
        </div>
      </div>
      <div className="leftt">
        <div className="leftti">
          <img src="/images/simg/Clean_rm_work.jpg" alt="" />
        </div>
        <div className="lefttd">
          <h3>ROOM WORK</h3>
          <p>
            Shivdarshan Cleanroom Doors widely used in cleaning workshops,
            hospitals, pharmaceutical factories, food factories, etc ,it has
            good performance at outside facer,flat ,high structural strength,
            corrosion resistance, no dust, easy to clean .the most important is
            easy installation for your projects.
          </p>
        </div>
      </div>
      <div className="rightt">
        <div className="righttd">
          <h3>AIR SHOWER</h3>
          <p>
            Our Air showers are self-contained air recirculation systems
            installed at entrances to cleanrooms and other controlled
            environments. Since people and products are the main sources of
            cleanroom contamination, these cleanroom entry systems quickly and
            efficiently remove particulate contaminants from workers or products
            before they enter a clean space. It’s available as per customised
            design and requirements.
          </p>
        </div>
        <div className="rightti">
          <img src="/images/simg/p1.jpeg" alt="" />
        </div>
      </div>
      <div className="leftt">
        <div className="leftti">
          <img src="/images/simg/p2.jpeg" alt="" />
        </div>
        <div className="lefttd">
          <h3>DYNAMIC PASS BOX</h3>

          <p>
            Shivdarshan Enterprises’s Steel Furniture is known for the
            manufacturer and supplier of the best quality Dynamic Pass Box with
            high accuracy. Dynamic pass box is used to transfer material from an
            uncontrolled environment to controlled environment. It is works like
            an airlock. It may be cascade, Sink and Bubble type. Dynamic pass
            box has a system which is known as interlock guard located on both
            sides. An interlock monitor framework controls the in let and the
            out let of PASS BOX not be opened simultaneously, so as to forestall
            the cross pollution. From the time being, we are helping our clients
            with the proper installation process of the Dynamic pass box in
            their industrial setups.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Product;
